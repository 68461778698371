/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.foraSwiper swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  @apply static flex justify-center;
}

.foraSwiper swiper-container::part(bullet-active) {
  @apply bg-stone md:hidden;
}

.foraSwiper swiper-container::part(bullet) {
  @apply bg-darkShell md:hidden;
}
