/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.foraSwiper swiper-container::part(button-prev),
.foraSwiper swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  @apply w-6 h-6;
  @apply bg-center bg-no-repeat bg-contain;
}

.foraSwiper swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.foraSwiper swiper-container::part(button-prev) .swiper-button-disabled {
  opacity: 0.35;
}

.foraSwiper swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.foraSwiper swiper-container::part(button-next) .swiper-button-disabled {
  opacity: 0.35;
}

@media (max-width: 768px) {
  .foraSwiper swiper-container::part(button-prev),
  .foraSwiper swiper-container::part(button-next) {
    @apply hidden;
  }
}
