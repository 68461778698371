/* Component: Advisor Reviews */

@import '../common/dots-pagination.module.css';
@import '../common/advisor-arrows-circle.module.css';

.foraSwiper swiper-slide {
  @apply h-auto;
}

.foraSwiper swiper-container::part(pagination) {
  @apply mt-8 lg:mt-6;
}

.foraSwiper swiper-container::part(button-next),
.foraSwiper swiper-container::part(button-prev) {
  top: -50px;
}

.foraSwiper swiper-container::part(button-prev) {
  right: 40px;
  left: unset;
}

.foraSwiper swiper-container::part(button-next) {
  right: 0px;
}

.foraSwiper swiper-container::part(container) {
  position: unset;
}
