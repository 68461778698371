/* Component: Advisor Reviews */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.featured-hotels_foraSwiper__80nmW swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  position: static;
  display: flex;
  justify-content: center;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(bullet-active) {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 115, 99, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .featured-hotels_foraSwiper__80nmW swiper-container::part(bullet-active) {
    display: none;
  }
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(bullet) {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 177, 164, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .featured-hotels_foraSwiper__80nmW swiper-container::part(bullet) {
    display: none;
  }
}

/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev),
.featured-hotels_foraSwiper__80nmW swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev) .featured-hotels_swiper-button-disabled__M0ho9 {
  opacity: 0.35;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-next) .featured-hotels_swiper-button-disabled__M0ho9 {
  opacity: 0.35;
}

@media (max-width: 768px) {
  .featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev), .featured-hotels_foraSwiper__80nmW swiper-container::part(button-next) {
    display: none;
  }
}

.featured-hotels_foraSwiper__80nmW swiper-slide {
  height: auto;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(pagination) {
  margin-top: 2rem;
}

@media (min-width: 1024px) {

  .featured-hotels_foraSwiper__80nmW swiper-container::part(pagination) {
    margin-top: 1.5rem;
  }
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-next),
.featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev) {
  top: -50px;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-prev) {
  right: 40px;
  left: unset;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(button-next) {
  right: 0px;
}

.featured-hotels_foraSwiper__80nmW swiper-container::part(container) {
  position: unset;
}

/* Component: Advisor Reviews */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  position: static;
  display: flex;
  justify-content: center;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(bullet-active) {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 115, 99, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .side-by-side-image_foraSwiper__HfmRq swiper-container::part(bullet-active) {
    display: none;
  }
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(bullet) {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 177, 164, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .side-by-side-image_foraSwiper__HfmRq swiper-container::part(bullet) {
    display: none;
  }
}

/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev),
.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev) .side-by-side-image_swiper-button-disabled__bI5xd {
  opacity: 0.35;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next) .side-by-side-image_swiper-button-disabled__bI5xd {
  opacity: 0.35;
}

@media (max-width: 768px) {
  .side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev), .side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next) {
    display: none;
  }
}

.side-by-side-image_foraSwiper__HfmRq swiper-slide {
  height: auto;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(pagination) {
  margin-top: 1rem;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next),
.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev) {
  top: -50px;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-prev) {
  right: 40px;
  left: unset;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(button-next) {
  right: 0px;
}

.side-by-side-image_foraSwiper__HfmRq swiper-container::part(container) {
  position: unset;
}

/* Component: Advisor Reviews */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(pagination) {
  position: static;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

@media (min-width: 1024px) {

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(pagination) {
    margin-top: 1.5rem;
  }
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(bullet-active) {
  background-color: transparent;
  text-decoration: underline;
}

/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev),
.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  display: none;
  height: 1.5rem;
  width: 1.5rem;
}

@media (min-width: 1024px) {

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev), .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) {
    display: block;
  }
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev), .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) .advisor-image-carousel_swiper-button-disabled__TrCx7 {
  opacity: 0.35;
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) .advisor-image-carousel_swiper-button-disabled__TrCx7 {
  opacity: 0.35;
}

.advisor-image-carousel_foraSwiper__90zwV swiper-slide {
  height: auto;
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(pagination) {
  margin-top: 2rem;
}

@media (min-width: 1024px) {

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(pagination) {
    margin-top: 1.5rem;
  }
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next), .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
  bottom: 0px;
}

@media (min-width: 768px) {

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next), .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
    top: -50px !important;
  }
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next),
.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
  top: unset;
}

@media (min-width: 768px) {

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
    right: 40px;
  }

  .advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-prev) {
    left: auto;
  }
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(button-next) {
  right: 0px;
}

.advisor-image-carousel_foraSwiper__90zwV swiper-container::part(container) {
  position: unset;
}

