/* Component: Advisor Reviews */

@import '../common/number-pagination.module.css';

/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.foraSwiper swiper-container::part(button-prev),
.foraSwiper swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  @apply hidden w-6 h-6 lg:block;
  @apply bg-center bg-no-repeat bg-contain;
}

.foraSwiper swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.foraSwiper swiper-container::part(button-prev) .swiper-button-disabled {
  opacity: 0.35;
}

.foraSwiper swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.foraSwiper swiper-container::part(button-next) .swiper-button-disabled {
  opacity: 0.35;
}

.foraSwiper swiper-slide {
  @apply h-auto;
}

.foraSwiper swiper-container::part(pagination) {
  @apply mt-8 lg:mt-6;
}

.foraSwiper swiper-container::part(button-next),
.foraSwiper swiper-container::part(button-prev) {
  @apply md:!top-[-50px] bottom-0;
  top: unset;
}

.foraSwiper swiper-container::part(button-prev) {
  @apply md:right-[40px] md:left-auto;
}

.foraSwiper swiper-container::part(button-next) {
  @apply right-0;
}

.foraSwiper swiper-container::part(container) {
  position: unset;
}
